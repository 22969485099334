<template>
    <div>
      <canvas id="bar-chart" class="w-full p-6 h-96"></canvas>
    </div>
  </template>
  
  <script setup>
  import Chart from "chart.js";
  import { onMounted, ref, defineProps } from "vue";
  
  const props = defineProps({
    data: {
      type: Array,
      required: true,
    },
  });
  
  const chartData = ref(props.data);
  const config = {
    type: "bar",
    data: {
      labels: chartData.value?.map((question, index) => {
        return `Practice${index + 1}`;
      }),
      datasets: [
        {
          label: "Practice Average",
          data: chartData.value?.map((i) => {
            return i.average;
          }),
          backgroundColor: "#00A499",
          stacked: "stack",
          barPercentage: 0.5,
        }
      ],
    },
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
      aspectRatio: 14 / 3,
      legend: {
        display: true,
        position: "bottom",
        align: "start",
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipLabel(tooltipItem);
          },
          title: function (tooltipItem, data) {
            return tooltipTitle(tooltipItem, data);
          },
        },
      },
    },
  };
  
  const chartObj = ref(null);
  const chartConfig = ref(config);
  
  const tooltipLabel = (tooltipItem) => {
    const index = tooltipItem.index;
    return "Practice: " + chartData.value[index]?.practice_name;
  };
  const tooltipTitle = (tooltipItem, data) => {
    return `${data.datasets[tooltipItem[0].datasetIndex].label}: ` + tooltipItem[0].value;
  };
  
  onMounted(() => {
    chartObj.value = new Chart(
      document.getElementById("bar-chart"),
      chartConfig.value
    );
  });
  </script>
  